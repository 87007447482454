import { useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from "react-redux";
import webBanner from '../../images/webBanner.jpg';
import { getBannerFrontend } from '../../reducers/frontEndReducer';

function WebSlider() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBannerFrontend())
  },[])
  const { getBannerFrontendData } = useSelector(
    (state) => state.frontEndReducer
  );
  return (
    <>
      {/* <img className='slider-web-size' src={webBanner}/> */}
      <Carousel variant="light">
        {!!getBannerFrontendData?.list &&
          getBannerFrontendData?.list.map((banner, index) => {
            return (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 slider-web-size"
                  src={banner?.image}
                  alt={banner?.title}
                />
              </Carousel.Item>
            );
          })}
      </Carousel>
    </>
  );
}

export default WebSlider;