import React from "react";
import Footer from "../Sub/Footer";
import webBanner from "../../images/webBanner.jpg";
import CommonHeader from "../Sub/CommonHeader";
import Banner from "../Sub/Banner";
import { Col, Row } from "react-bootstrap";
import BrandsLogo from "../Sub/BrandsLogo";

const About = () => {
  let width = window.innerWidth;
  return (
    <>
      <CommonHeader />
      <div>
        <Banner bannerImg={webBanner} />
      </div>
      <div className="container-fluid">
        <div className={`page-main ${width >= 468 && "p-5"}`}>
          <Row>
            <h1 className="mb-4">ABOUT US</h1>
            <p>
            Our company (<b>SHREE HIRA COMPUTER & COMMUNICATION</b>) started in 2003.
            </p>
            {/* <div className="border-y mb-4">
              <h2 className="mb-0">
                Lorem ipsum <span>dolor sit amet</span>, consectetur adipiscing
                elit. Nunc hendrerit tellus et nisi ultra trices, eu feugiat
                sapien commodo. Praesent vitae ipsum et risus.
              </h2>
            </div> */}
            <p>
            Broadly we are working in following items.
            </p>
            <p className="mb-0">1. Computer Parts</p>
            <p className="mb-0">2. CCTV</p>
            <p className="mb-0">3. Antivirus/Software</p>
            <p className="mb-0">4. Import/refurbished desktop & Laptop</p>
            <p>
            We have more than <b>500</b> different products available.
            </p>
            <p>
            We are supplying material in <b>PAN INDIA</b> 
            </p>
            <p>With this website we want to give <b>minimum</b> price directly/indirectly to our dealers.</p>
            <p><b>Our GST Details & Address</b></p>
            <p>
            SHREE HIRA COMPUTER and COMMUNICATIONS<br/>

            SHOP NO.10, LAJPAT RAI MARKET<br/>

            OPP.ELITE CINEMA, RAILWAY ROAD<br/>

            Hisar -125001
            </p>
            <p><b>Contact no:- 9728622667</b></p>
            <p><b>GST NO. 06AFCPB0886L1ZH</b></p>
          </Row>
          {/* <div className="section__head justify-content-between mb-40">
            <div className="section__title">
              <h3>What We Do?</h3>
            </div>
          </div>
          <Row>
            <Col md={4}>
              <div className="banner__item mb-3 w-img">
                <a href="#">
                  <img
                    src="https://wphix.com/template/topico-prev/topico/assets/img/about/about-1.jpg"
                    alt=""
                  />
                </a>
              </div>
              <h3>What Do We Do?</h3>
              <p>
                Suspendisse turpis ipsum, tempus in nulla eu, posuere pharetra
                nibh. In dignissim vitae lorem non mollis.
              </p>
            </Col>
            <Col md={4}>
              <div className="banner__item mb-3 w-img">
                <a href="#">
                  <img
                    src="https://wphix.com/template/topico-prev/topico/assets/img/about/about-2.jpg"
                    alt=""
                  />
                </a>
              </div>
              <h3>Our Mission?</h3>
              <p>
                Suspendisse turpis ipsum, tempus in nulla eu, posuere pharetra
                nibh. In dignissim vitae lorem non mollis.
              </p>
            </Col>
            <Col md={4}>
              <div className="banner__item mb-3 w-img">
                <a href="#">
                  <img
                    src="https://wphix.com/template/topico-prev/topico/assets/img/about/about-3.jpg"
                    alt=""
                  />
                </a>
              </div>
              <h3>History Of Us?</h3>
              <p>
                Suspendisse turpis ipsum, tempus in nulla eu, posuere pharetra
                nibh. In dignissim vitae lorem non mollis.
              </p>
            </Col>
          </Row> */}
          <BrandsLogo />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
