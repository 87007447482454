import React from "react";
import {  useSelector } from "react-redux";
import { Row, Col, Button, Container } from "react-bootstrap";
import CommonHeader from "../../Sub/CommonHeader";
import { rewardSite } from "../../../const";

  
const GoToRewardWebsite = () => {
  const { rewardfromPcdealsReward } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
        <CommonHeader/>
        <Container>
          <Row>
            <Col md={12} className="mt-5 text-center">
                <h1 className="text-success text-bold">
                You have <span className="text-danger">{rewardfromPcdealsReward ? rewardfromPcdealsReward : 0}</span> rewards points. To use these reward points <Button className="bg-thememain" onClick={() => window.open(`${rewardSite}?key=${localStorage.getItem("x-auth-token")}`, "_blank")}>Click Here</Button>
                </h1>
            </Col>
          </Row>
        </Container>
    </>
  );
};

export default GoToRewardWebsite;
