import React, { useEffect } from "react";
import BestSelling from "../Sub/BestSelling";
import Features from "../Sub/Features";
import Footer from "../Sub/Footer";
import OnSaleProducts from "../Sub/OnSaleProducts";
import WebSlider from "../Sub/WebSlider";
import Review from "../Sub/Review";
import { Col, Container } from "react-bootstrap";
import BrandsLogo from "../Sub/BrandsLogo";
import homebanner from '../../images/homebanner.jpg';
import { useDispatch, useSelector } from "react-redux";
import {
  getHomePageSetting,
  getProductRating,
} from "../../reducers/commonReducer";
import Header from "../Sub/Header";
import Categories from "../Sub/Categories";
import { userProductLikedList } from "../../reducers/orderDetailsReducer";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHomePageSetting());
    dispatch(getProductRating());
    localStorage.getItem("x-auth-token") && dispatch(userProductLikedList());
  }, []);
  const { getHomePageSettingList } = useSelector(
    (state) => state.commonReducer
  );
  const { getCountProductData } = useSelector((state) => state.frontEndReducer);
  return (
    <>
      <Header />
      <Categories />
      {/* <CommonHeader /> */}
      {!!getHomePageSettingList?.home?.show_main_slider && (
        <div className="container-fluid mt-3 mbl-margin-0">
          <WebSlider />
        </div>
      )}
      {!!getHomePageSettingList?.home?.showwebfeatures && (
        <Features mobileHide={"mbl-hide"} />
      )}
      {!!getHomePageSettingList?.home?.showproductslider && <BestSelling />}
      {!!getHomePageSettingList?.home?.showbanner && (
        <section className="banner__area">
          <div className="container">
            <div className="row">
              <Col xs={12} md={12} lg={12}>
                <div className="banner__item  w-img">
                  <img
                    src={homebanner}
                    alt="Pc deals Hardware"
                  />
                </div>
              </Col>
            </div>
          </div>
        </section>
      )}
      {!!getHomePageSettingList?.home?.showlatestproduct && (
        <div className="container">
          <OnSaleProducts
            productsDetails={getCountProductData}
            title={"Products"}
          />
        </div>
      )}
      {!!getHomePageSettingList?.home?.showclientreview && <Review />}
      {!!getHomePageSettingList?.home?.showbrandslogoslider && (
        <Container>
          <BrandsLogo />
        </Container>
      )}
      <Footer />
    </>
  );
};

export default Home;
